<template>
    <div class="page-wrap">
        <div v-if="createzujian==true">
            <createtemplate @close-view="returnList" ></createtemplate>
        </div>
        <div v-else-if="editComponent==true">
            <editcomponent :editId="editid" @close-view="returnList1"  ></editcomponent>
        </div>
        <div v-else-if="showTable">
            <div class="l-box">          
                <div class="input-group">
                    <div class="flex f-wrap">
                        <div class="wrap flex">
                            <div class="r-find">
                            <Input v-model="formValidate.xinxi" placeholder="请输入模板名称进行搜索" style="width: 300px" />
                            </div>
                        </div>            
                    </div>
                    <div class="flex f-wrap" >            
                        <div class="r-btn flex">
                            <Button  @click="getListSearch" type="primary">查询</Button>                             
                        </div>
                    </div>
                </div>
            </div>      
            <div class="table_card">
                <div class="table-wrap">    
                    <Button type="primary" @click="creatTem">创建</Button>  
                </div>
                <Table :columns="columnTable" :loading="loading" :data="dataTable">
                    <template slot-scope="{ row }" slot="documentType">
                        <p>{{ row.document_type === 1 ?'Word文档':'' }}</p>
                        <p>{{ row.document_type === 3 ?'Pdf':'' }}</p>
                        <p>{{ row.document_type !== 3 && row.document_type !== 1 ?'图片':'' }}</p>
                    </template>
                    <template slot-scope="{row}" slot="hide">
                        <!-- {{ row.id }} -->
                        <i-switch v-model="row.is_template_switch" :value="row.is_template_switch"  :true-value="1" :false-value="0" size="large" @on-change="changeBan(row)">
                            <span slot="open">开启</span>
                            <span slot="close">关闭</span>
                        </i-switch>                     
                    </template>

                    <template slot-scope="{row}" slot="action">
                        <div class="flex-c">
                            <div class="table-btn blue" @click="kaiqi(row)">{{ row.status_ == 1 ? '启用' : '禁用' }}</div>
                            <div class="table-btn edit" @click="editTem(row)">编辑</div>
                            <div class="table-btn danger" @click="delTemplete(row.id)">删除</div>
                        </div>
                    </template>
                </Table>
                <Page :total="total"
                    :current="formValidate.page"
                    style="text-align:right;margin-top: 20px"
                    show-elevator
                    show-total
                    @on-change="pageChange"
                    :page-size="formValidate.limit"/>
            </div>
            <modal v-if="showModal" :modalInfo="modalInfo" @giveVal="getVal" @closeModal="showModal=false"></modal>
        </div>
        <div v-else>
            <Preview ref="preview" @titleSync="seeBack"></Preview>
        </div>
    </div>
</template>

<script>
import modal from '@/components/modal/index'
import Preview from '../../components/preview/index'
import  createtemplate from './components/createtemplate.vue'
import  editcomponent from './components/editcomponent.vue'
import {
    edittempupdate,
    addtemplate,
    templist,
    typetemplate,
    deleteTable,
    edittemplate,
    delEnclosure,
    set_template_switch
} from '../../api/interFace'
export default {
    name: "index",
    components:{
        modal,
        Preview,
        createtemplate,
        editcomponent
    },
    data(){
        return{
            createzujian: false,//创建组件
            editComponent: false,
            showModal: false,
            showTable: true,
            loading: false,
            is_template_sharing:false,  //是否开启共享功能
            editid: 0,
            total: 0,  
            dataTable: [],
            useId: '',
            formValidate: {
                xinxi: '',//测试查询假名称
                template_name: '',
                contract_imgs:[],
                template_content: '',
                status: "",
                page: 1,
                limit: 10,
                document_type:'',
                upload_type:2
            },            
            modalInfo: {
                title: '修改模板名称',
                inputList: [
                    {
                        label: '模板名称',
                        inputType: 'text',
                        inputVal: ''
                    },
                ]
            },           
            columnTable: [],    
            columnTableA:[
                {
                    title: '序号',
                    key: 'id',
                    align: 'center',
                    width: 80
                },
                {
                    title: '模板名称',
                    key: 'template_name',
                    align: 'center'
                },
                {
                    title: '模板编号',
                    key: 'numbering',
                    align: 'center'
                },
                {
                    title: '模板类型',
                    key: 'document_type',
                    align: 'center',
                    slot: 'documentType',
                },
                {
                    title: '创建时间',
                    key: 'created_at',
                    align: 'center'
                },
                {
                    title: '模板状态',
                    key: 'status',
                    align: 'center'
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 320,
                    align: 'center'
                }
            ]  , 
            columnTableB:[
                {
                    title: '序号',
                    key: 'id',
                    align: 'center',
                    width: 80
                },
                {
                    title: '模板名称',
                    key: 'template_name',
                    align: 'center'
                },
                {
                    title: '模板编号',
                    key: 'numbering',
                    align: 'center'
                },
                {
                    title: '模板类型',
                    key: 'document_type',
                    align: 'center',
                    slot: 'documentType',
                },
                {
                    title: '创建时间',
                    key: 'created_at',
                    align: 'center'
                },
                {
                    title: '模板状态',
                    key: 'status',
                    align: 'center'
                },
                {
                    title: '是否共享',
                    slot: 'hide',
                    align: 'center'
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 320,
                    align: 'center'
                }
            ]     
        }
    },
    created() {
        this.$store.commit('changePollingSpin', false)
        this.is_template_sharing = localStorage.getItem("is_template_sharing") == 1 ? true : false 
        this.columnTable = []
        // &&

        console.log('this.$store.state.userInfo',)
        if(this.is_template_sharing && this.$store.state.userInfo.role == 2){
            this.columnTableB.forEach( item =>{
                this.columnTable.push(item)
            })
        } else {
            this.columnTableA.forEach( item =>{
                this.columnTable.push(item)
            })
        }
        this.getlist()
    },
    methods:{
        seeBack(){
            this.showTable = true
        },
        changeBan(row){
            let type = row.is_template_switch == 0 ? 0 : 1
            let data = {status: type,template_id: row.id}
            // https://cscontract.hulianzaowu.top/adminapi/temp/set_template_switch
            set_template_switch(data).then(res => {
                this.$Message.success(res.msg)
                this.getList()
            }).catch(res => {
                this.$Message.error(res.msg)
                this.getList()
            })
        },
        pageChange(index) {
            this.formValidate.page = index
            this.getlist()
        },
        // 开启状态
        kaiqi(row) {
            let status = null
            let content
            if (row.status_ == 0) {
                status = 1
                content = '请问您是否确认禁用此模板'
            } else {
                status = 0
                content = '请问您是否确认启用此模板'
            }
            this.$Modal.confirm({
                title: '操作提示',
                content: content,
                onOk: () => {
                    typetemplate({id: row.id, status: status}).then(async res => {
                        this.getlist()
                        this.$Message.success(res.msg)
                    }).catch(res => {
                        this.$Message.error(res.msg)
                    })
                },
                onCancel: () => {
                }
            });
        },
        returnList(val) {
            this.createzujian = val
        },
        returnList1(val) {
            this.editComponent = val
        },
        delTemplete(id) {
            this.$Modal.confirm({
                title: '操作提示',
                content: '请问您是否确认删除此模板，删除后无法恢复！！！',
                onOk: () => {
                deleteTable({id:id}).then(res=>{
                    this.$Message.success(res.msg)
                    this.getlist()
                }).catch(res=>{
                    this.$Message.error(res.msg)
                })
                },
                onCancel: () => {
                }
            });
        },        
        getListSearch(){
            this.formValidate.page = 1
            this.loading = true
            templist({
                status: "",
                page: this.formValidate.page, // 当前页
                limit: this.formValidate.limit,
                title: this.formValidate.xinxi        
            }).then(async res => {
                this.total = res.data.total
                this.dataTable = res.data.data || []
                this.loading = false
            }).catch(res => {
                this.loading = false
                this.$Message.error(res.msg)
            })
        },
        //创建模板
        creatTem(){
            this.createzujian = true 
        },
        editTem(e){
            this.editid = e.id
            this.editComponent =true
        },
        handleBeforeChange (e) {
            console.log('eeeee',e)
            return new Promise((resolve) => {
                this.$Modal.confirm({
                    title: '切换确认',
                    content: '您确认要切换开关状态吗？',
                    onOk: () => {                        
                        // set_template_switch({status:this.seal_switch == 0 ? 1 : 0}).then(res=>{
                        //     this.$Message.success(res.msg)
                        //     this.get_sealstatus()
                        // }).catch(res=>{
                        //     this.$Message.error(res.msg)
                        // })
                        resolve();
                    }
                });
            });
        },
        getlist() {
            this.loading = true
            templist({
                status: "",
                page: this.formValidate.page, // 当前页
                limit: this.formValidate.limit
            }).then(async res => {
                this.total = res.data.total
                this.dataTable = res.data.data || []
                this.loading = false
            }).catch(res => {
                this.loading = false
                this.$Message.error(res.msg)
            })
        },
        getVal(e) {
            edittemplate({
                template_name: e[0],
                id: this.useId
            }).then(async res => {
                this.$Message.success(res.msg)
                this.showModal = false
                this.getlist()
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        }
    }
}
</script>

<style scoped lang="scss">
.page-wrap {    
    .table_card {
        width: 100%;
        min-height: 700px;
        padding: 24px;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 8px;
        position: relative;
        margin-top: 20px;        
        .creat {
            width: 64px;
            height: 28px;
            background: #FFFFFF;
            border: 1px solid #4877E8;
            border-radius: 6px;
            font-size: 14px;
            font-weight: bold;
            color: #4877E8;
            margin-top: 20px;
            margin-bottom: 20px;
            cursor: pointer;
            &:hover{
                background: #4877E8;
                color: white;
            }
        }        
    }
    .first-in {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);        
    }
    .choose-first {
        .top-nav {
            width: 100%;
            background: #FFFFFF;
            border-radius: 12px;
            padding: 21px 20px;
            box-sizing: border-box;
            .table-btn {
                margin-top: 20px;
                margin-left: 110px;
            }
            .input-group {
                margin-top: 20px;
                .wrap {
                    margin-right: 20px;
                }
                .label {
                    font-size: 14px;
                    color: #545F78;
                }
            }
        }
        .company {
            margin-top: 20px;
            width: 100%;
            background: #FFFFFF;
            border-radius: 12px;
            padding: 21px 20px;
            box-sizing: border-box;            
        }        
    }
    .submit-btn {
        width: 100%;
        margin-top: 20px;
        height: 110px;
        cursor: pointer;
        background: #FFFFFF;
        border-radius: 12px;
        border: 2px solid #DBDEE6;
        box-sizing: border-box;
        &:hover {
            border: 2px solid #4877E8;
        }
        .iconfont {
            font-size: 50px;
            color: #4877E8;
        }
        span {
            font-size: 28px;
            font-weight: bold;
            color: #1B2742;
            margin-left: 12px;
        }
    }
    .submit-btn-else {
        border: unset;
    }
    .l-box {
        background: #FFFFFF;
        width: 100%;
        padding: 20px 20px;
        border-radius: 8px;     
    }
    .input-group {
        display: flex;
    }
    .r-btn {
        margin-left: 30px;       
    }
    .table-wrap {
        width: 100%;      
        box-sizing: border-box;
        border-radius: 8px;
        background: white;
        margin-bottom:16px ;        
        .blue {
            margin-left: unset;        
        }
    } 
}
</style>
