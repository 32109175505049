<template>
    <div>
        <div v-if="yulan">
            <Preview ref="preview" @titleSync="seeBack"></Preview>
        </div>
        <!-- 开头 -->
        <div v-else>
            <div class="l-box">  
                <!-- @click="goBack"    -->
                <div class="top_change" >
                    <div class="l_where">编辑模板</div>
                    <div class="r_back" @click="goBack"><i class="iconfont icon-fanhui"></i></div>
                </div>     
            </div>
            <div class="l-box">
                <div class="top-secend">
                    <div class="top-twoleft" >
                        <div class="l_where">基本信息</div>                        
                    </div>   
                    <div class="information"> 
                        <div style="line-height:32px">模板名称:</div>
                        <Input v-model="formItem.template_name" placeholder="请输入模板名称" style="width: 200px;margin-left:8px" />                                       
                    </div>               
                    <div  class="topright-but" >                        
                        <Button @click="modal"  type="primary" >保存</Button> 
                    </div>  
                </div> 
            </div>
            <!-- 主体 -->
            <div class="tibody" style="display:flex" >      
                <!-- 右侧 -->
                <div class="right_body" >
                    <!-- 选择附件  v-else-->
                    <div  class="right_body2">
                        <div class="hetong" :style="'position: absolute;   left:'+hetongjuzhong+'px;  width: 800px;word-break: break-all;'">             
                            <div ref="pronbit" >
                                <div v-if="mySealList.length>0" >
                                        <!-- 个人签章 start 已有的 -->
                                    <div v-if="jurList.indexOf('template-components') !== -1">
                                        <div v-for="(item,index) in mySealList" :key="index"  > 
                                            <!-- 图片  -->
                                            <div v-if="item.type == 4 || item.type == 6">
                                                <div  :style="'padding:0 5px;cursor: default;left:'+item.position_x+'px;top:'+item.position_y+'px;position: absolute;z-index: 100;width: '+item.width+'px;height:'+item.height+'px;border: 1px solid #FE0201;margin: 10px;cursor: move;overflow: hidden;'"
                                                >
                                                    <div :style="'padding:0 5px;display:flex;line-height:'+item.height+'px;background:white;font-size:'+item.font_size+'px'">
                                                        {{item.title}}                                            
                                                    </div>
                                                </div>
                                                <div :style="'left:'+item.position_x+'px;top:'+item.position_y+'px;position: absolute;z-index: 100;width:'+item.outside_width+'px;height: '+item.outside_height+'px;  align-items: center;padding: 10px;cursor: move;'"
                                                    @mousedown="moveSeal($event,index)">                                
                                                    <div>                                    
                                                        <div :style="' color: #ffffff;position: absolute;left: '+(item.outside_width-10)+'px;width: 25px;height: 25px;writing-mode: vertical-lr;display: flex;align-items: center;justify-content: center;font-size: 14px;font-weight: bold;cursor: pointer;'"
                                                            @click="xiugaiSeal(index,item.type)"><img style="width:25px;height:25px;"  src="../../../assets/imgs/xiangqing.png">
                                                        </div>   
                                                        <div :style="' color: #ffffff;margin-top:25px; position: absolute;left: '+(item.outside_width-10)+'px;width: 25px;height: 25px;writing-mode: vertical-lr;align-items: center;display: flex;justify-content: center;font-size: 14px;font-weight: bold;cursor: pointer;'"
                                                            @click="closeSeal(index)"><img style="width:25px;height:25px;" src="../../../assets/imgs/shanchu.png">
                                                        </div>      
                                                    </div>  
                                                </div>
                                            </div>
                                            <!-- 文字 -->
                                            <div v-else>
                                                <div  :style="'cursor: default;left:'+item.position_x+'px;top:'+item.position_y+'px;position: absolute;z-index: 100;width: '+item.width+'px;height:'+item.height+'px;border: 1px solid #FE0201;margin: 10px;cursor: move;overflow: hidden;'"
                                                >
                                                    <div :style="'display:flex;background:white;font-size:'+item.font_size+'px'">
                                                        {{item.title}}                                            
                                                    </div>
                                                </div>
                                                <div :style="'left:'+item.position_x+'px;top:'+item.position_y+'px;position: absolute;z-index: 100;width:'+item.outside_width+'px;height: '+item.outside_height+'px;  align-items: center;padding: 10px;cursor: move;'"
                                                    @mousedown="moveSeal($event,index)">
                                                    <div>                                    
                                                        <div :style="' color: #ffffff;position: absolute;left: '+(item.outside_width-10)+'px;width: 25px;height: 25px;writing-mode: vertical-lr;display: flex;align-items: center;justify-content: center;font-size: 14px;font-weight: bold;cursor: pointer;'"
                                                            @click="xiugaiSeal(index,item.type)"><img style="width:25px;height:25px;"  src="../../../assets/imgs/xiangqing1.png">
                                                        </div>   
                                                        <div :style="' color: #ffffff;margin-left:25px; position: absolute;left: '+(item.outside_width-10)+'px;width: 25px;height: 25px;writing-mode: vertical-lr;align-items: center;display: flex;justify-content: center;font-size: 14px;font-weight: bold;cursor: pointer;'"
                                                            @click="closeSeal(index)"><img style="width:25px;height:25px;" src="../../../assets/imgs/shanchu.png">
                                                        </div>      
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                        <!-- 签署  新建的 -->
                                <div v-if="showSeal1"
                                    :style="'left:'+position_x+'px;top:'+position_y+'px;position: absolute;z-index: 100;width: 160px;height: 50px;margin: 10px;cursor: move;overflow: hidden;'">
                                    <img style="width:100%;height:100%" :src="qianshuImga">
                                </div>
                                <div v-for="(item,index) in img_list" :key="index" class="bg-img" id="tlinK"><img :src="item"></div>
                            </div>
                            <div style="height:200px;background-color: #edf0f9;"></div>
                        </div>
                        
                        <div class="left_body" v-if="jurList.indexOf('template-components') !== -1" >
                            <div class="nav_title" style="margin-top:20px;font-size: 15px;margin-bottom:30px">
                                <span>组件</span>           
                            </div>
                            <div >
                                <div v-if="showAssembly">
                                    <div>
                                        <Card style="margin-bottom:20px">
                                            <span>默认组件宽、高与字体大小：</span>  
                                            <Button @click="componentSizeModification">修改</Button>
                                        </Card>
                                    </div>
                                    <Button  class="personalSeal personal" @click="xingmingzujian(0)">
                                        <div class="new-zujian" >
                                            <img src="../../../assets/imgs/xingming.png">
                                            <div class="ziti" > 姓名</div>
                                        </div>
                                    </Button>
                                    <Button class="personalSeal personal" @click="xingmingzujian(1)">                                        
                                        <div class="new-zujian" >
                                        <img src="../../../assets/imgs/dianhua.png">
                                        <div class="ziti" >电话</div>
                                    </div>
                                    </Button>
                                    <Button class="personalSeal personal" @click="xingmingzujian(2)">
                                        <div class="new-zujian" >
                                            <img src="../../../assets/imgs/dizhi.png">
                                            <div class="ziti" >地址</div>
                                        </div>
                                    </Button>   
                                    <Button  class="personalSeal personal" @click="xingmingzujian(3)">
                                        <div class="new-zujian" >
                                            <img src="../../../assets/imgs/shenfenzhenghaoma.png">
                                            <div class="ziti" >身份证号码</div>
                                        </div>
                                    </Button>
                                    <Button class="personalSeal personal" @click="xingmingzujian(4)">
                                        <div class="new-zujian" >
                                            <img src="../../../assets/imgs/shenfenzhengzhaopian.png">
                                            <div class="ziti" >身份证照片</div>
                                        </div>
                                    </Button>
                                    <Button class="personalSeal personal" @click="xingmingzujian(5)">
                                        <div class="new-zujian" >
                                            <img src="../../../assets/imgs/jine.png">
                                            <div class="ziti" >金额</div>
                                        </div>
                                    </Button>  
                                    <Button  class="personalSeal personal" @click="xingmingzujian(6)">
                                        <div class="new-zujian" >
                                            <img src="../../../assets/imgs/gongzhang.png">
                                            <div class="ziti" >公章</div>
                                        </div>
                                    </Button>
                                    <Button class="personalSeal personal" @click="xingmingzujian(7)">
                                        <div class="new-zujian" >
                                            <img src="../../../assets/imgs/riqi.png">
                                            <div class="ziti" >日期</div>
                                        </div>
                                    </Button>
                                    <Button class="personalSeal personal" @click="xingmingzujian(8)">
                                        <div class="new-zujian" >
                                            <img src="../../../assets/imgs/gongsimingcheng.png">
                                            <div class="ziti" >公司名称</div>
                                        </div>
                                    </Button>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 组件大小弹窗 -->
        <Modal
            title="编辑方框宽高"
            v-model="sizeChange"
            :mask-closable="false"
            width="600"
            :footer-hide="true"
            class-name="vertical-center-modal">
            <Form ref="boxSize"  :model="boxSize" :label-width="140" style="width: 500px">
                <FormItem label="组件类别" >
                    <div class="tpye_cla">{{ getType() }}</div>
                </FormItem>
                <FormItem label="组件名称" prop="sealName">
                    <Input v-model="boxSize.sealName" type="text" placeholder="请输入组件名称"></Input>
                </FormItem>
                <FormItem v-if="showText" label="字体大小" prop="font_size">
                    <Input v-model="boxSize.font_size" type="text" placeholder="请输入字体大小"></Input>
                </FormItem>
                <FormItem v-if="showPhotos" label="宽" prop="width">
                    <Input v-model="boxSize.width" type="text" placeholder="请输入宽度"></Input>
                </FormItem>
                <FormItem v-if="showPhotos" label="高" prop="height">
                    <Input v-model="boxSize.height" type="text" placeholder="请输入高度"></Input>
                </FormItem>         
                <FormItem>
                    <Button type="primary" @click="handleMessage('boxSize')">确认</Button>
                    <Button @click="resetMessage('boxSize')" style="margin-left: 8px">关闭</Button>
                </FormItem>
            </Form>
        </Modal>
        <!-- 组件大小弹窗 -->
        <Modal
            title="修改组件默认宽、高与字体大小"
            v-model="initialComponent"
            :mask-closable="false"
            width="600"
            :footer-hide="true"
            class-name="vertical-center-modal">
            <Form ref="initialBoxSize"  :model="initialBoxSize" :label-width="140" style="width: 500px">
                <FormItem label="字体大小" prop="font_size">
                    <Input v-model="initialBoxSize.font_size" type="text" placeholder="请输入字体大小"></Input>
                </FormItem>
                <FormItem label="宽" prop="width">
                    <Input v-model="initialBoxSize.width" type="text" placeholder="请输入宽度"></Input>
                </FormItem>
                <FormItem label="高" prop="height">
                    <Input v-model="initialBoxSize.height" type="text" placeholder="请输入高度"></Input>
                </FormItem>         
                <FormItem>
                    <Button type="primary" @click="handleform('initialBoxSize')">确认</Button>
                    <Button @click="defaultResetMessage('initialBoxSize')" style="margin-left: 8px">关闭</Button>
                </FormItem>
            </Form>
        </Modal>
    </div>
</template>

<script>
import { getCookies } from '@/utils/helper'
import config from '@/config/index'
import Preview from '../../../components/preview/index'
import { info,edittemplate,deletetmp } from '../../../api/interFace'
export default {
    name: "index",
    components:{ Preview },
    props: {
        editId:Number,
    },       
    data(){
        return{
            boxSize: {
                sealName:"",
                title:"",
                width:"",
                height:"",
                font_size:"",
                outside_width:"",
                outside_height:"",
                type:""
            }, 
            initialBoxSize: {
                width:"",
                height:"",
                font_size:"",
                outside_width:"",
                outside_height:"",
                type:""
            },      
            buttondel: false ,
            createzujian: false ,
            //butxianshi: false,//左侧按钮显示
            //yzuoce: false, //右侧样式
            yulan: false, //预览
            showSeal1: false, // 是否使用个人印章
            showAssembly:true,//身份不够则不能点击组件样式 
            showPhotos:false, //弹框照片样式
            showText:false, //弹框文字样式
            divx: 160,
            divy: 50,
            divxx: 180,
            divyy: 70,
            font_size: 18,
            hetongjuzhong:340,
            chazhi: 20,
            boxindex: 0,
            //上传文件
            headers:{'Authori-zation' : getCookies('token')},
            imageAction:config.apiUrl + '/adminapi/common/uploads',
            img_list:[],//图片
            formItem: {
                template_name: '',
                name: '',
                sms_status: '',
                document_type: '',
                upload_type: '',
                start_time: '',
                end_time: '',
                paraticipant_phone: '',
                support_num: '',
                contract_imgs: [],
                corporate_name: '',
                requirement: '',
                type: '',
                template_id:'',
                label_id:'',
                assembly_arr: [] ,//测试      
                jurList:[], 
                path:""  
            },         
            //组件印章
            mySealList:[],
            nowWhere:700,    
            title:"" ,
            sizeChange:false,//修改大小弹窗
            initialComponent:false,//初始组件弹窗
            numberArr:[0,0,0,0,0,0,0,0,0],
            name_arr:["姓名","电话","地址","身份证号码","身份证照片","金额","公章","日期","公司名称"]
        }
    },
    created(){
        this.jurList = this.$store.state.auth
        if(this.jurList.indexOf('template-components') == -1){
            this.hetongjuzhong = 0
        }
        let str=this.$store.state.identity
        if( str == 2 ){
            this.showAssembly = false
        } 
        info({id: this.editId}).then(async res => {
            this.formItem=res.data
            this.formItem.template_name=res.data.template_name
            this.formItem.contract_imgs=res.data.contract_imgs
            this.formItem.document_type= res.data.document_type
            this.formItem.upload_type= res.data.upload_type    
            this.img_list=  this.formItem.contract_imgs
            this.formItem.path = res.data.path//
            this.mySealList=res.data.components_arr
            res.data.components_arr.forEach(item=>{
                let j = item.type
                this.numberArr[j] =  this.numberArr[j]+1
            })
            this.mySealList.forEach( item =>{
                item.outside_width=parseInt( item.width)+20
                item.outside_height=parseInt( item.height)+20
            })
            this.total = res.data.total     
            this.loading = false
        }).catch(res => {
            this.loading = false
            this.$Message.error(res.msg)
        })
    },
    mounted() {
        window.addEventListener('scroll', this.handleScrollx, true)
    },
    methods:{
        goBack(){
            this.$emit("close-view",false)
            this.$parent.getlist();
        },
        // 开启姓名组件
        xingmingzujian(e) {
            this.numberArr[e] = this.numberArr[e] + 1
            this.title = this.name_arr[e] +  this.numberArr[e]
            this.bottom = this.$refs.pronbit.clientHeight
            var pageId = document.querySelector('#tlinK')          
            if( e == 4 || e == 6){
                this.mySealList.push({
                    position_x:pageId.offsetLeft,position_y:pageId.offsetTop + this.nowWhere,type:e,title:this.title,
                    width:this.divx,height:this.divy,outside_width:parseInt(this.divx) + parseInt(this.chazhi),outside_height:parseInt(this.divy) + parseInt(this.chazhi),
                    font_size:this.font_size,sealName:"",id:""
                })
            }else{
                let textWight = this.getTextWidth(this.title,this.font_size)
                let textHeight = this.getTextHeight(this.title,this.font_size)
                this.mySealList.push({
                    position_x:pageId.offsetLeft,position_y:pageId.offsetTop + this.nowWhere,type:e,title:this.title,
                    width:textWight,height:textHeight,outside_width:parseInt(textWight) + parseInt(this.chazhi),outside_height:parseInt(textHeight) + parseInt(this.chazhi),
                    font_size:this.font_size,sealName:"",id:""
                })
            }
        },
        getTextWidth(text, fontSize) {
            // 通过文字获得宽
            const _span = document.createElement('span')
            // 放入文本
            _span.innerText = text
            // 设置文字大小
            _span.style.fontSize = fontSize + 'px'
            // span元素转块级
            _span.style.position = 'absolute'
            // span放入body中
            document.body.appendChild(_span)
            let width = _span.offsetWidth        
            // 从body中删除该span
            document.body.removeChild(_span)
            return width
        },
        //通过文字获得高
        getTextHeight(text, fontSize) {
            // 创建临时元素
            const _span = document.createElement('span')
            // 放入文本
            _span.innerText = text
            // 设置文字大小
            _span.style.fontSize = fontSize + 'px'
            // span元素转块级
            _span.style.position = 'absolute'
            // span放入body中
            document.body.appendChild(_span)
            let height = _span.offsetHeight
            // 从body中删除该span
            document.body.removeChild(_span)
            return height
        },
        // 滚动滑轮获取当前页数
        handleScrollx() {
            if (this.$refs.pronbit) {
                let topBounding = Math.abs(this.$refs.pronbit.getBoundingClientRect().top - 110);
                this.nowWhere= topBounding + 500
                this.nowPage = Math.ceil(topBounding / 1100);
            }
        },
        //默认尺寸修改
        handleform(name){          
            this.$refs[name].validate((valid) => {           
                if (valid) {
                    if(isNaN(this.initialBoxSize.width)||isNaN(this.initialBoxSize.height)||isNaN(this.initialBoxSize.font_size)){
                        return this.$Message.warning('请输入数字且不能有空格')
                    }else {                       
                        this.divy=this.initialBoxSize.height
                        this.divx=this.initialBoxSize.width
                        this.font_size=this.initialBoxSize.font_size
                        this.divxx=parseInt(this.initialBoxSize.width)+parseInt(this.chazhi)
                        this.divyy=parseInt(this.initialBoxSize.height)+parseInt(this.chazhi)
                        this.mySealList.forEach(item =>{
                            if(item.type == 4 || item.type==6){
                                item.height=this.initialBoxSize.height
                                item.width=this.initialBoxSize.width
                                item.outside_height=parseInt(this.initialBoxSize.height)+parseInt(this.chazhi)
                                item.outside_width=parseInt(this.initialBoxSize.width)+parseInt(this.chazhi)
                            }else{
                                item.font_size=this.initialBoxSize.font_size
                                item.height=this.getTextHeight(item.title,this.initialBoxSize.font_size)
                                item.width=this.getTextWidth(item.title,this.initialBoxSize.font_size)
                                item.outside_height=parseInt( item.height)+parseInt(this.chazhi)
                                item.outside_width=parseInt( item.width)+parseInt(this.chazhi)
                            }
                        })
                        this.initialComponent=false
                    }               
                } else {
                    return
                }
            })
        },
        //提交
        handleMessage(name){          
            this.$refs[name].validate((valid) => {           
                if (valid) {
                    if(isNaN(this.boxSize.width) || isNaN(this.boxSize.height) || isNaN(this.boxSize.font_size) || this.boxSize.sealName == ""
                    ||this.boxSize.width == "" || this.boxSize.height == "" || this.boxSize.font_size == "" ){
                        return this.$Message.warning('请输入组件姓名且字体大小、宽、高为数字')
                    }else {    
                        if(this.boxSize.type == 4 || this.boxSize.type == 6){                            
                            this.mySealList[this.boxindex].height = this.boxSize.height
                            this.mySealList[this.boxindex].width = this.boxSize.width                            
                            this.mySealList[this.boxindex].outside_width = parseInt(this.boxSize.width)+parseInt(this.chazhi)
                            this.mySealList[this.boxindex].outside_height = parseInt(this.boxSize.height)+parseInt(this.chazhi)                            
                        } else {
                            this.mySealList[this.boxindex].height = this.getTextHeight(this.mySealList[this.boxindex].title,this.boxSize.font_size)
                            this.mySealList[this.boxindex].width = this.getTextWidth(this.mySealList[this.boxindex].title,this.boxSize.font_size)
                            this.mySealList[this.boxindex].outside_width = parseInt(  this.mySealList[this.boxindex].width)+parseInt(this.chazhi)
                            this.mySealList[this.boxindex].outside_height = parseInt( this.mySealList[this.boxindex].height)+parseInt(this.chazhi)                           
                        }
                        this.mySealList[this.boxindex].title = this.boxSize.sealName
                        this.mySealList[this.boxindex].sealName = this.boxSize.sealName
                        this.mySealList[this.boxindex].font_size = this.boxSize.font_size
                        this.sizeChange = false
                        this.showText = false
                        this.showPhotos = false
                    }               
                } else {
                    return
                }
            })
        },
        //大小弹窗关闭
        resetMessage(name){
            this.sizeChange=false
            this.showPhotos=false
            this.showText=false
            this.$refs[name].resetFields();
        },
           //默认大小关闭
        defaultResetMessage(name){
            this.initialComponent = false;  
            this.$refs[name].resetFields();
        },
         // 组件位置
        moveSeal(e,index) {
            let odiv = e.target;
            let disX = e.clientX - odiv.offsetLeft;
            let disY = e.clientY - odiv.offsetTop;  
            var pageId = document.querySelector('#tlinK')         
            //鼠标移动事件
            document.onmousemove = (en) => {
                if (en.clientX - disX <= (pageId.offsetLeft - 20) ||
                //右侧抵达位置
                en.clientX - disX >= (pageId.offsetLeft + 800-this.mySealList[index].outside_width) || 
                en.clientY - disY <= pageId.offsetTop || 
                en.clientY - disY >=
                this.$refs.pronbit.clientHeight - 70)
                    return false
                let left = en.clientX - disX;
                let top = en.clientY - disY;
                this.mySealList[index].position_x = left;
                this.mySealList[index].position_y = top;                    
                odiv.style.left = left + 'px';
                odiv.style.top = top + 'px';    
            };          
            //鼠标移动停止事件
            document.onmouseup = (e) => {
                console.log("11",e)
                document.onmousemove = null;
                document.onmouseup = null;          
            };                 
        },       
         // 关闭组件
        closeSeal(index) {
            let type = this.mySealList[index].type;
            let name = this.name_arr[type];
            this.numberArr[type] = this.numberArr[type]-1
            this.mySealList.splice(index,1)
            let j = 1;
            //重新赋值
            for ( var l in this.mySealList) {
                console.log('xxxx',this.mySealList[l])
                var m = this.mySealList[l]
                if (m.type == type) {   
                    // "姓名","电话","地址","身份证号码","身份证照片","金额","公章","日期","公司名称"
                    if(type == 0 && (m.title.substr(0,2)=='姓名')){
                        m.title = name+j
                    }else if(type== 1 && (m.title.substr(0,2)=='电话')){
                        m.title = name+j
                    }else if(type== 2 && (m.title.substr(0,2)=='地址')){
                        m.title = name+j
                    }else if(type== 3 && (m.title.substr(0,5)=='身份证号码')){
                        m.title = name+j
                    }else if(type== 4 && (m.title.substr(0,5)=='身份证照片')){
                        m.title = name+j
                    }else if(type== 5 && (m.title.substr(0,2)=='金额')){
                        m.title = name+j
                    }else if(type== 6 && (m.title.substr(0,2)=='公章')){
                        m.title = name+j
                    }else if(type== 7 && (m.title.substr(0,2)=='日期')){
                       m.title = name+j
                    }else if(type== 8 && (m.title.substr(0,4)=='公司名称')){
                        m.title = name+j
                    }else {
                        console.log('不可变')
                        // m.title = m.title
                    }                     
                     j++
                      if( !m.sealName=="" ){
                          m.title=m.sealName
                      }
                }
            }
        },
        componentSizeModification(){
            this.initialComponent = true
            this.initialBoxSize.width=this.divx
            this.initialBoxSize.height=this.divy            
            this.initialBoxSize.font_size=this.font_size           
        },
        getType(){
            let type = this.boxSize.type  
            let name = ''
            if(type == 0){
                name = '姓名'
            }else if(type== 1){
                name = '电话'
            }else if(type== 2 ){
                name = '地址'
            }else if(type== 3 ){
                name = '身份证号码'
            }else if(type== 4 ){
                name = '身份证照片'
            }else if(type== 5 ){
                name = '金额'
            }else if(type== 6 ){
                name = '公章'
            }else if(type== 7 ){
                name = '日期'
            }else if(type== 8 ){
                name = '公司名称'
            }else {
                name = '暂无'
            }     
            return name
        },
          // 修改组件
        xiugaiSeal(index,type) {
            if( type == 4 || type == 6){
                this.showPhotos = true
                this.showText = false
            } else {
                this.showText = true
                this.showPhotos = false
            }
            this.boxSize.sealName = this.mySealList[index].title  
            this.boxSize.width = parseInt(this.mySealList[index].width)
            this.boxSize.height = parseInt(this.mySealList[index].height)
            this.boxSize.outside_height = this.mySealList[index].outside_height
            this.boxSize.outside_width = this.mySealList[index].outside_width
            this.boxSize.font_size = parseInt(this.mySealList[index].font_size)
            this.boxSize.type = parseInt(this.mySealList[index].type)
            this.boxindex=index
            this.sizeChange=true            
        },
        //验证组件是否跨页面
        spreadPage(){
            let foramData = {
                seal_position:[],           
            } 
            this.isCoverYear = true
            this.mySealList.forEach(item=> {              
                let num = Math.ceil((item.position_y+10)/1132)//10蓝虚线不算
                let i = item.position_y-(num-1)*1132+10 //左上角高度
                let k = i+parseInt(item.height)//组件在单页高度k
                if(k >= 1132){     
                    this.isCoverYear=false              
                }else{
                    foramData.seal_position.push(item)
                }              
            })
            return this.isCoverYear
        },
         //设置保存去
        modal() {                  
            //验证组件是否跨页面
            this.spreadPage()
            if(!this.isCoverYear){
                this.$Message.warning('组件不能跨两页')
                return
            }  
            if (this.formItem.template_name == '') return this.$Message.warning('请填写模板名称')  
                this.mySealList.forEach(item=>{
                let j=(parseInt(item.position_y))/1132
                let k= Math.ceil(j)
                item.page= k
                item.sealName = ""
           })                
            this.formItem.assembly_arr = this.mySealList            
            edittemplate( this.formItem).then(async res => {   
                this.formItem.template_name = ''
                this.formItem.template_content = ''
                this.formItem.contract_imgs = []
                this.mySealList=[]    
                this.$emit("close-view",false)
                this.$Message.success(res.msg)
                this.$parent.getlist();
            }).catch(res => {
                this.$Message.error(res.msg)
            })  
        },   
        seeBack(){
            this.yulan = false
        }
    }
};
</script>

<style scoped lang="scss">
.top-secend {
    width: 100%;
    background: #FFFFFF;
    padding: 0px 30px;
    margin-bottom: 20px;
    box-sizing: border-box;
    height: 60px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    .top-twoleft {
        display: flex;
        justify-content: center;
        align-items: center; 
        .l_where {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            user-select: none;
            font-size: 15px;
            font-weight: bold;
            color: #1B2742;
            span {
                color: #4877E8;
            }
        }       
    }
    .information{
        display: flex;
        justify-content:center;
        align-items: center;                
    }
}
// 开头样式
.top_change {
    width: 100%;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    box-sizing: border-box;
    margin-bottom: 20px;
    height: 60px;
    .l_where {
        font-size: 18px;
        font-weight: bold;
        color: #1B2742;
        span {
            color: #4877E8;
        }
    }
    .r_back {
        .iconfont {
            font-size: 26px;
            cursor: pointer;
            &:hover {
                color: #4877E8;
            }
        }
    }
}
.tpye_cla {
    opacity: 0.6;
}
.tibody {
    width: 100%;
    height: 10px; 
    position: relative;      
    .left_body{
        width: 310px;         
        background: #FFFFFF;
        border-radius: 8px;
        align-items: center;
        padding: 0px 10px;
        box-sizing: border-box;
        margin-bottom: 20px;
        margin-right:20px;
        height: 100vh;       
    }      
    .right_body {
        width:100%;
        .right_body1 {
            width: 100%;   
            background: #FFFFFF;
            border-radius: 8px;
            padding: 0px 30px;
            box-sizing: border-box;
            margin-bottom: 20px;
            height: 90vh;
        }
        .right_body2 {
            display: flex;          
            width: 100%;
            .hetong {       
                position: absolute;   
                //滚动
                -webkit-overflow-scrolling: touch;
                overflow-x: hidden;
                white-space: nowrap;
                /* 定义滚动条样式 */
                ::-webkit-scrollbar {
                    width: 6px;
                    height: 6px;
                }            
                background: #FFFFFF;
                width: 800px;
                border-radius: 8px;
                align-items: center;         
                box-sizing: border-box;            
                height: 100vh;
                margin-left:auto;
                margin-right: auto; 
                left:0;right:0;top:0;bottom:0;
            }
            .zujian {
                background: #FFFFFF;
                overflow: hidden;
                width: 25%;
                position: absolute;             
                right: 70px; 
                border-radius: 8px;
                align-items: center;
                padding: 0px 30px;
                box-sizing: border-box;
                margin-bottom: 20px;
                margin-left:20px;
                height: 100vh;
            }
        }        
    }     
}
  // 中间样式
.bg-img {
    width: 800px;
    height: 1132px;
    margin: auto;
    background: white;
    border-bottom: 1px solid #8a8f97;
    box-sizing: border-box;
    box-shadow:0 10px 10px rgba(0, 0, 0, .3);
}
.bg-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: auto;
    display: inline-block;
}
// 右侧组件样式
.personalSeal {      
    padding-left: 40px ;
    align-items: flex-start !important; 
    display: flex !important; 
    justify-content: flex-start!important; 
    width: 90%;   
    height: 52px;    
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 12px;
    border-radius: 8px;  
    .new-zujian {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 50px;
    }   
    .ziti {
        font-size:20px;
        color:#545F78;
    }  
    img {
        width: 24px;
        height: 24px;
        margin-right: 24px;    
    }
}
.personal {
    border: 1px solid #DBDEE6;
    color: #DBDEE6;
    box-shadow: 0px 0px 10px 2px rgba(142, 187, 235, 0.2);
}
// .operationBtn {
//     /*margin: 0 20px;*/
//     border: 1px solid #2C8CF0;
//     color: #2C8CF0;
//     box-shadow: 0px 0px 10px 2px rgba(44, 140, 240, 0.2);
// }
</style>